var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isBidder)?_c('el-row',{staticClass:"fast-links-menu"},[(_vm.pending)?_c('el-col',{attrs:{"sm":6}},[_c('el-col',{class:[
        'fs-20 fw-l',
        {
          declined: _vm.pending > 0,
          'fw-n':
            (!_vm.currentStatus || _vm.currentStatus === 'pending') &&
            _vm.isAccreditationPage
        }
      ]},[_vm._v(" Новых запросов ("+_vm._s(_vm.pending)+") ")]),_c('el-col',{staticClass:"t-grey fs-20 mt-10 mb-40 fw-l"},[_c('span',{staticClass:"bb cp",on:{"click":function($event){return _vm.checkRoute('')}}},[_vm._v(" Показать всех перевозчиков ")])])],1):_vm._e(),_c('el-col',{attrs:{"sm":6}},[_c('el-col',{class:[
        'fs-20 black',
        _vm.$route.name === 'BiddersGroups' ? 'fw-n' : 'fw-l'
      ]},[_vm._v(" Группы перевозчиков ("+_vm._s(_vm.groupsCount)+") ")]),_c('el-col',{staticClass:"t-grey fs-20 mt-10 mb-40 fw-l"},[_c('span',{staticClass:"bb cp",on:{"click":function($event){return _vm.goTo('BiddersGroups')}}},[_vm._v(" Управлять группами ")])])],1),_c('el-col',{attrs:{"sm":6}},[_c('el-col',{class:[
        'fs-20 black',
        _vm.$route.name === 'Invitations' ? 'fw-n' : 'fw-l'
      ]},[_vm._v(" Приглашения в систему ("+_vm._s(_vm.inviteCount)+") ")]),_c('el-col',{staticClass:"t-grey fs-20 mt-10 mb-40 fw-l"},[_c('span',{staticClass:"bb cp",on:{"click":function($event){return _vm.goTo('Invitations')}}},[_vm._v(" Управлять приглашениями ")])])],1),(_vm.okExpiresSoon)?_c('el-col',{attrs:{"sm":6}},[_c('el-col',{class:[
        'fs-20 fw-l',
        {
          declined: _vm.okExpiresSoon > 0,
          'fw-n': _vm.currentStatus === 'ok_expires_soon' && _vm.isAccreditationPage
        }
      ]},[_vm._v(" Договоры на пролонгацию ("+_vm._s(_vm.okExpiresSoon)+") ")]),_c('el-col',{staticClass:"t-grey fs-20 mt-10 mb-40 fw-l"},[_c('span',{staticClass:"bb cp",on:{"click":function($event){return _vm.checkRoute('ok_expires_soon')}}},[_vm._v(" Показать договоры ")])])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }