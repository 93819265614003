<template>
  <el-row
    type="flex"
    align="middle"
    justify="space-between"
    class="i-filters fs-15"
  >
    <el-col v-if="!isMobile">
      <span class="mr-20 mr-t-10">показать по</span>
      <el-select v-model="perPage" class="mr-20 mr-t-10 per-page">
        <el-option v-for="item of perPageOptions" :key="item" :value="item" />
      </el-select>
      <span>записей</span>
    </el-col>
    <el-col v-if="isStatus">
      <div class="i-filters__select">
        <span v-if="!isMobile" class="mr-20">Статус</span>
        <el-select v-model="status" clearable placeholder="Выберите из списка">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </el-col>
    <search-input :value="search" @change="onChange" />
    <el-col
      v-if="isAdd"
      :sm="3"
      :xs="3"
      :offset="1"
      :class="['add-btn-div ta-r', { 'no-before': !isShowHelper }]"
    >
      <div v-if="isShowHelper" class="float-block fs-13">
        Ваша компания пока не аккредитована<br />
        ни у одного заказчика<br /><br />
        Если у вас заключен договор с одной из компаний,<br />
        аккредитуйтесь для участия в торгах
      </div>
      <el-button type="success" class="add-button" @click="$emit('add')">
        +
      </el-button>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { perPageOptions } from './constants'
import SearchInput from '@/components/Ui/SearchInput'

export default {
  name: 'Filters',
  components: { SearchInput },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    accreditation: {
      type: Boolean,
      default: false
    },
    archive: {
      type: Boolean,
      default: false
    },
    isStatus: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      perPageOptions,
      timer: null
    }
  },
  watch: {
    showHelper: {
      immediate: true,
      handler(val) {
        if (val && this.isItems && this.accreditation && this.isBidder) {
          this.setHelperShown()
        }
      }
    }
  },
  computed: {
    ...mapGetters('accreditations', ['showHelper', 'isItems', 'currentStatus']),
    isAdd() {
      if (this.archive) return false
      return this.accreditation ? this.isBidder : !this.isBidder
    },
    isShowHelper() {
      return this.showHelper && this.accreditation && this.isBidder
    },
    perPage: {
      get() {
        return this.filters['per-page']
      },
      set(val) {
        this.onChange('per-page', val)
      }
    },
    status: {
      get() {
        return this.accreditation ? this.currentStatus : this.filters.status
      },
      set(val) {
        this.accreditation ? this.setStatus(val) : this.onChange('status', val)
      }
    },
    search: {
      get() {
        return this.filters.any
      }
    }
  },
  methods: {
    ...mapMutations('accreditations', ['setHelperShown', 'setCurrentStatus']),
    onChange(key, val = undefined) {
      this.$emit('change', key, val)
    },
    setStatus(val) {
      this.setCurrentStatus(val)
      this.$emit('change')
    }
  },
  beforeDestroy() {
    if (this.isBidder && this.accreditation) clearTimeout(this.timer)
  }
}
</script>

<style lang="sass">
@import '~@/assets/styles/mixins.sass'

@keyframes leftFrame
  0%
    transform: translate(-100px, 0)
  100%
    transform: translate(0, 0)

.i-filters
  color: $textGreyDarkColor2
  @include to(768px)
    padding: 0 20px
    .search-input
      .el-input
        width: 100% !important
  @include to(550px)
    flex-wrap: wrap

  .el-select.per-page
    width: 75px
  .add-button
    width: 45px
    height: 33px
    border-radius: 10px
    padding: 0
    font-weight: bold
    font-size: 30px
    line-height: 1
  .add-btn-div
    position: relative
    .float-block
      position: absolute
      width: 315px
      top: 135px
      right: 115px
      color: $blackColor
      z-index: 1
      animation: leftFrame 2s ease-in-out
    &:after
      content: ''
      position: absolute
      background: url('~@/assets/imgs/icons/curve-arrow-icon.svg')
      width: 100px
      height: 138px
      top: 35px
      right: 10px
      z-index: 1
      animation: leftFrame 2s ease-in-out
    &.no-before:after
      background: none
      width: 0
      height: 0
  &__select
    display: flex
    flex: 0 0 auto
    justify-content: flex-start
    align-items: center
    flex-wrap: wrap
    @include to(1045px)
      flex: 0 0 100%
  .el-select
    width: auto
    @include to(1045px)
      width: 90%
    @include to(550px)
      width: 100%
      margin-bottom: 17px
</style>
