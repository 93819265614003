<template>
  <el-row v-if="!isBidder" class="fast-links-menu">
    <el-col v-if="pending" :sm="6">
      <el-col
        :class="[
          'fs-20 fw-l',
          {
            declined: pending > 0,
            'fw-n':
              (!currentStatus || currentStatus === 'pending') &&
              isAccreditationPage
          }
        ]"
      >
        Новых запросов ({{ pending }})
      </el-col>
      <el-col class="t-grey fs-20 mt-10 mb-40 fw-l">
        <span class="bb cp" @click="checkRoute('')">
          Показать всех перевозчиков
        </span>
      </el-col>
    </el-col>
    <el-col :sm="6">
      <el-col
        :class="[
          'fs-20 black',
          $route.name === 'BiddersGroups' ? 'fw-n' : 'fw-l'
        ]"
      >
        Группы перевозчиков ({{ groupsCount }})
      </el-col>
      <el-col class="t-grey fs-20 mt-10 mb-40 fw-l">
        <span class="bb cp" @click="goTo('BiddersGroups')">
          Управлять группами
        </span>
      </el-col>
    </el-col>
    <el-col :sm="6">
      <el-col
        :class="[
          'fs-20 black',
          $route.name === 'Invitations' ? 'fw-n' : 'fw-l'
        ]"
      >
        Приглашения в систему ({{ inviteCount }})
      </el-col>
      <el-col class="t-grey fs-20 mt-10 mb-40 fw-l">
        <span class="bb cp" @click="goTo('Invitations')">
          Управлять приглашениями
        </span>
      </el-col>
    </el-col>
    <el-col v-if="okExpiresSoon" :sm="6">
      <el-col
        :class="[
          'fs-20 fw-l',
          {
            declined: okExpiresSoon > 0,
            'fw-n': currentStatus === 'ok_expires_soon' && isAccreditationPage
          }
        ]"
      >
        Договоры на пролонгацию ({{ okExpiresSoon }})
      </el-col>
      <el-col class="t-grey fs-20 mt-10 mb-40 fw-l">
        <span class="bb cp" @click="checkRoute('ok_expires_soon')">
          Показать договоры
        </span>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'FastLinkMenu',
  created() {
    if (!this.isBidder) {
      this.groupsFETCH()
      this.inviteFETCH()
    }
  },
  computed: {
    ...mapGetters({
      getCount: 'accreditations/getCount',
      currentStatus: 'accreditations/currentStatus',
      getPaginationGroups: 'biddersGroups/getPagination',
      getPaginationInvite: 'invitations/getPagination'
    }),
    groupsCount() {
      return this.getPaginationGroups.totalCount || 0
    },
    inviteCount() {
      return this.getPaginationInvite.totalCount || 0
    },
    pending() {
      return this.getCount('pending')
    },
    isAccreditationPage() {
      return this.$route.name === 'Accreditations'
    },
    okExpiresSoon() {
      return this.getCount('ok_expires_soon')
    }
  },
  methods: {
    ...mapMutations('accreditations', ['setCurrentStatus']),
    ...mapActions({
      groupsFETCH: 'biddersGroups/FETCH',
      inviteFETCH: 'invitations/FETCH'
    }),
    checkRoute(status) {
      if (!this.isAccreditationPage) {
        this.$router.push({ name: 'Accreditations' })
      }
      const oldStatus = this.currentStatus
      this.setCurrentStatus(status)

      if (this.isAccreditationPage && status !== oldStatus) {
        this.$emit('change')
      }
    },
    goTo(name) {
      this.$router.push({ name })
    }
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.fast-links-menu
  @include to(768px)
    margin: 0 15px
</style>
